import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, up } from '@xstyled/styled-components'

import Container from './container'
import BtnTranslate from '../btn-translate'
import BtnNavMobile from '../btn-nav-mobile'

import LogoSSYMasterPlan from '../../svgs/logos/logo-ssy-master-plan.svg'

const StyledHeader = styled.header`
  height: 60rpx;
  padding: 0;
  margin-bottom: 70rpx;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${up(
    'md',
    css`
      height: auto;
      padding: 30rpx 0 70rpx;
      margin-bottom: 70rpx;
    `
  )}
`

const HeaderTop = styled.div`
  margin-bottom: 30rpx;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const HeaderBottom = styled.div`
 display: flex;
 justify-content: center;

 .logo-ssy-master-plan {
    width: 260rpx;
    height: auto;
    justify-self: center;
    position: relative;
    top: -6rpx;

    ${up(
      'md',
      css`
        width: 500rpx;
      `
    )}

     & > g > g {
       fill: var(--ssy-orange);
     }
  }
`

const Header = ({ lang, homepage, data }) => {
  if (homepage) {
    return null
  }
  
  return (
    <StyledHeader>
      <Container>
        <HeaderTop>
          <BtnTranslate lang={lang} data={data} hideMobile />
          <BtnNavMobile lang={lang} data={data}/>
        </HeaderTop>
        <HeaderBottom>
          <a href={`/${lang === 'EN' ? '' : `${lang}/`}`} aria-label="homepage"><LogoSSYMasterPlan /></a>
        </HeaderBottom>
      </Container>
    </StyledHeader>
  )
}

export default Header

Header.defaultProps = {
  homepage: false,
  lang: 'EN'
}

Header.propTypes = {
  homepage: PropTypes.bool,
  lang: PropTypes.string
}
