import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'
import styled, { css, down } from '@xstyled/styled-components'

import IconTranslateOrange from '../images/iconTranslateOrange.svg'
import IconTranslate from '../svgs/icons/icon-translate.svg'
import IconChevronDownCircle from '../svgs/icons/icon-chevron-down-circle.svg'

const StyledBtnDropDown = styled.div`
  position: relative;
  width: fit-content;

  ${down(
    'md',
    css`
      display: ${p => p.hideMobile ? `none` : 'block'};
    `
  )}

  .btn-dropdown {
    font-size: 12rpx;
    font-weight: bold;
    padding: 10rpx 26rpx;
    background-color: var(--ssy-white);
    border: 0;

    .icon-translate {
      position: relative;
      top: 1rpx;
      width: 14rpx;
      height: 12rpx;
    }

    .icon-chevron-down-circle {
      position: relative;
      top: 1rpx;
      width: 10rpx;
      height: 10rpx;

      g > g {
        fill: var(--ssy-orange);
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: var(--ssy-white);
      background-color: var(--ssy-black);

      .icon-translate g > g,
      .icon-chevron-down-circle g > g {
        fill: var(--ssy-white);
      }
    }
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    padding: 10rpx 0;
    flex-direction: column;
    flex-grow: 1;
    right: 0;
    color: var(--ssy-black);
    background-color: var(--ssy-white);
    z-index: 98;
    width: 100%;

    &.display {
      display: flex;
    }
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 4rpx 0;
    font-size: 12rpx;
    line-height: 24rpx;
    font-weight: 700;
    color: var(--ssy-black);
    text-align: center;
    text-decoration: none;
    transition: color 0.5s ease-in-out;

    &:hover {
      color: var(--ssy-orange);
    }
  }
`

const StyledSelect = styled.select`
  -webkit-appearance: none;
  display: block;
  font-size: 12rpx;
  font-weight: bold;
  padding: 10rpx 26rpx;
  background-image: url(${IconTranslateOrange});
  background-repeat: no-repeat;
  background-size: 13.6rpx auto;
  background-position: 10px 12px;
  background-color: var(--ssy-white);
  margin: 100rpx auto 0;
  border: 0;
`

const BtnTranslate = ({ data, lang, hideMobile, navMobile, select }) => {
  let path = globalHistory.location.pathname

  const toggleMenu = (e) => {
    const btn = e.target
    const dropdown = btn.parentElement
    const menu = btn.nextElementSibling;

    if (navMobile) {
      dropdown.style.height = `${btn.clientHeight + menu.clientHeight}px`
      menu.style.width = `${btn.clientWidth}px`
    }

    menu.classList.toggle('display')
    btn.classList.toggle('active')


    document.addEventListener('click', (e) => {
      const isClickable = dropdown.contains(e.target)

      if (navMobile) {
        dropdown.style.height = `0`
      }

      if (!isClickable) {
        menu.classList.remove('display')
        btn.classList.remove('active')
      }
    })
  }

  const handlePathname = () => {
    if (lang !== 'EN') {
      path = path.replace(`/${lang}`, '')
    }
  }

  const handleSelect = (e) => {
    // if the path is empty then default it to '/'
    window.location.href = e.target.value || '/'
  }

  handlePathname();

  if (select) {
    return (
      <StyledSelect onChange={(e) => handleSelect(e)}>
        <option className="default" value="/" default>{data.translations.cta}</option>
        <option value={`${path}`}>English</option>
        <option value={`/ES${path}`}>Spanish</option>
      </StyledSelect>
    )
  } else {
    return (
      <StyledBtnDropDown
        className="dropdown btn-translate"
        hideMobile={hideMobile}
        navMobile={navMobile}
        >
        <button
          type="button"
          aria-label="change translation"
          className="btn-dropdown"
          onClick={e => toggleMenu(e)}
        >
          {navMobile ? (<IconTranslateOrange />) : (<IconTranslate />)} {data.translations.cta} {!navMobile && (<IconChevronDownCircle />)}
        </button>
        <div className="dropdown-menu">
          {data.translations.languages.map(item => (
            <Link key={item.name} className="dropdown-item" to={`${item.link}${path}`}>{item.name}</Link>
          ))}
        </div>
      </StyledBtnDropDown>
    )
  }
}

export default BtnTranslate

BtnTranslate.defaultProps = {
  hideMobile: false,
  select: false,
  lang: 'EN'
}

BtnTranslate.propTypes = {
  lang: PropTypes.string,
  hideMobile: PropTypes.bool,
  select: PropTypes.bool
}