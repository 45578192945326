import { css, up } from '@xstyled/styled-components'

// import Fonts from '../../fonts/fonts'
import IconBulletOrange from '../../images/icon-bullet-orange.svg'

import '../../fonts/hco_fonts/hco_fonts.css'

const Typography = css`
  /* import local fonts */

  :root {
    --font-gotham: 'HCo Gotham SSm', sans-serif;
  }

  body {
    font-family: var(--font-gotham) !important;
    font-weight: 400 !important;
  }

  /* Mobile breakpoint & Up */

  h1 {
    font-size: 48rpx;
    line-height: 54rpx;
    font-weight: 800;
    margin-bottom: 24rpx;
  }

  h2 {
    font-size: 28rpx;
    line-height: 36rpx;
    font-weight: 800;
    margin-bottom: 24rpx;
  }

  h3 {
    font-size: 24rpx;
    line-height: 30rpx;
    font-weight: 800;
    margin-bottom: 24rpx;
  }

  h4 {
    font-size: 18rpx;
    line-height: 24rpx;
    font-weight: 800;
    margin-bottom: 24rpx;
  }

  h5 {
    font-size: 18rpx;
    line-height: 24rpx;
    font-weight: 800;
    margin-bottom: 24rpx;
  }

  h6 {
    font-size: 18rpx;
    line-height: 24rpx;
    font-weight: 800;
    margin-bottom: 24rpx;
  }

  .h1 {
    font-size: 48rpx;
    line-height: 54rpx;
    font-weight: 800;
  }

  .h2 {
    font-size: 28rpx;
    line-height: 36rpx;
    font-weight: 800;
  }

  .h3 {
    font-size: 24rpx;
    line-height: 30rpx;
    font-weight: 800;
  }

  .h4 {
    font-size: 18rpx;
    line-height: 24rpx;
    font-weight: 800;
  }

  .h5 {
    font-size: 18rpx;
    line-height: 24rpx;
    font-weight: 800;
  }

  .h6 {
    font-size: 13rpx;
    line-height: 13rpx;
    font-weight: 800;
  }

  label {
    font-size: 15rpx;
    line-height: 30rpx;
    font-weight: 800;
    margin-bottom: 8rpx;
  }

  p {
    font-size: 15rpx;
    line-height: 24rpx;
    font-weight: 400;
    margin-bottom: 30rpx;
  }

  b,
  strong {
    font-weight: 800;
  }

  i {
    font-style: italic
  }

  em, .highlight {
    font-size: inherit;
    font-weight: inherit;
    color: var(--ssy-orange)
  }

  ul {
    list-style-type: none;
    font-size:  15rpx;
    line-height: 24rpx;
    font-weight: 400;
    padding-inline-start: 58px;

    li {
      margin-bottom: 8rpx;

      &::before {
        content:  '';
        display: block;
        position: relative;
        width: 6rpx;
        height: 6rpx;
        background-image: url(${IconBulletOrange});
        background-repeat: no-repeat;
        top: 16rpx;
        left: -32rpx;
      }
    }
  }

  ol {
    list-style-type: none;
    font-size:  15rpx;
    line-height: 24rpx;
    font-weight: 400;
    padding-inline-start: 58px;

    li {
      position: relative;
      counter-increment: list;
      margin-bottom: 8rpx;

      &::before {
        content:  counter(list);
        position: absolute;
        left: -32rpx;
        color: var(--ssy-orange);
        font-weight: 800;
      }
    }
  }


  /* MD (768px) breakpoint & Up */
  ${up(
    'md',
    css`
      h1 {
        font-size: 54rpx;
        line-height: 60rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      h2 {
        font-size: 48rpx;
        line-height: 54rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      h3 {
        font-size: 42rpx;
        line-height: 48rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      h4 {
        font-size: 36rpx;
        line-height: 42rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      h5 {
        font-size: 24rpx;
        line-height: 30rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      h6 {
        font-size: 18rpx;
        line-height: 24rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      .h1 {
        font-size: 54rpx;
        line-height: 60rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      .h1-alt {
        font-size: 72rpx;
        line-height: 80rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      .h2 {
        font-size: 48rpx;
        line-height: 54rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      .h3 {
        font-size: 42rpx;
        line-height: 48rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      .h4 {
        font-size: 36rpx;
        line-height: 42rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      .h5 {
        font-size: 24rpx;
        line-height: 30rpx;
        font-weight: 800;
      }

      .h6 {
        font-size: 18rpx;
        line-height: 24rpx;
        font-weight: 800;
        margin-bottom: 36rpx;
      }

      label {
        font-size: 18rpx;
        line-height: 18rpx;
        font-weight: 800;
        margin-bottom: 16rpx;
      }

      p {
        font-size: 18rpx;
        line-height: 30rpx;
        font-weight: 400;
      }

      ul {
        list-style-type: none;
        font-size:  18rpx;
        line-height: 30rpx;
        font-weight: 400;
        padding-inline-start: 58px;

        li {
          margin-bottom: 8rpx;

          &::before {
            content:  '';
            display: block;
            position: relative;
            width: 6rpx;
            height: 6rpx;
            background-image: url(${IconBulletOrange});
            background-repeat: no-repeat;
            top: 16rpx;
            left: -32rpx;
          }
        }
      }

      ol {
        list-style-type: none;
        font-size:  18rpx;
        line-height: 30rpx;
        font-weight: 400;
        padding-inline-start: 58px;

        li {
          position: relative;
          counter-increment: list;
          margin-bottom: 8rpx;

          &::before {
            content:  counter(list);
            position: absolute;
            left: -32rpx;
            color: var(--ssy-orange);
            font-weight: 800;
          }
        }
      }
    `,
  )}
`
export default Typography
