import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, up } from '@xstyled/styled-components'

const StyledContainer = styled.div`
  padding: 0 30rpx;
  max-width: 100%;

  ${up(
    'xl',
    css`
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 60rpx;
    `
  )}
`

const Container = ({ children, className }) => (
    <StyledContainer className={`container ${className}`}>
      {children}
    </StyledContainer>
);

export default Container;

Container.defaultProps = {
  className: '' 
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
}