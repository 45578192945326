/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle, ThemeProvider, defaultTheme } from "@xstyled/styled-components"
import { normalize } from 'styled-normalize'
import { reset } from 'styled-reset'

import Header from './header'
import Footer from './footer'
import PreFooter from './pre-footer'
import GlobalStyles from '../../styles/global/index'

export const theme = {
  ...defaultTheme,
  space: [0, 4, 8, 16, 24, 48, 96, 144, 192, 240],
  screens: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  colors: {
    primary: 'pink',
    blue100: '#070F23',
    blue200: '#1FDAFF',
    blue200A: 'rgba(31, 218, 255, .8)',
    gray100: '#F2F2F2',
    orange100: '#D9381C',
  },
}

const GlobalStyle = createGlobalStyle`
  ${reset}
  /* ${normalize} */
  ${GlobalStyles}
`

const Layout = ({ children, lang, homepage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      allEnJson {
        edges {
          node {
            cta {
              back
              learnMore
              downloadPlan
              downloadFullPlan
            }
            footerCopyright
            footerNav {
              link
              name
            }
            footerNewsLetter {
              placeHolder
              thankYou
              title
            }
            lightbox {
              cta {
                carousel
                enlarge
              }
            }
            mainNav {
              link
              name
            }
            newsEvents {
              cta {
                events
                news
                register
                viewAll
              }
              filter {
                all
                events
                news
                showing
              }
              title
              indexTitle
            }
            siteMetaData {
              author
              description
              title
            }
            translations {
              cta
              languages {
                link
                name
              }
            }
          }
        }
      }
      allEsJson {
        edges {
          node {
            cta {
              back
              learnMore
              downloadPlan
              downloadFullPlan
            }
            footerCopyright
            footerNav {
              link
              name
            }
            footerNewsLetter {
              placeHolder
              thankYou
              title
            }
            lightbox {
              cta {
                carousel
                enlarge
              }
            }
            mainNav {
              link
              name
            }
            newsEvents {
              cta {
                events
                news
                register
                viewAll
              }
              filter {
                all
                events
                news
                showing
              }
              title
              indexTitle
            }
            siteMetaData {
              author
              description
              title
            }
            translations {
              cta
              languages {
                link
                name
              }
            }
          }
        }
      }
      allZhHantJson {
        edges {
          node {
            cta {
              back
              learnMore
              downloadPlan
              downloadFullPlan
            }
            footerCopyright
            footerNav {
              link
              name
            }
            footerNewsLetter {
              placeHolder
              thankYou
              title
            }
            lightbox {
              cta {
                carousel
                enlarge
              }
            }
            mainNav {
              link
              name
            }
            newsEvents {
              cta {
                events
                news
                register
                viewAll
              }
              filter {
                all
                events
                news
                showing
              }
              title
              indexTitle
            }
            siteMetaData {
              author
              description
              title
            }
            translations {
              cta
              languages {
                link
                name
              }
            }
          }
        }
      }
      allKoJson {
        edges {
          node {
            cta {
              back
              learnMore
              downloadPlan
              downloadFullPlan
            }
            footerCopyright
            footerNav {
              link
              name
            }
            footerNewsLetter {
              placeHolder
              thankYou
              title
            }
            lightbox {
              cta {
                carousel
                enlarge
              }
            }
            mainNav {
              link
              name
            }
            newsEvents {
              cta {
                events
                news
                register
                viewAll
              }
              filter {
                all
                events
                news
                showing
              }
              title
              indexTitle
            }
            siteMetaData {
              author
              description
              title
            }
            translations {
              cta
              languages {
                link
                name
              }
            }
          }
        }
      }
      allArJson {
        edges {
          node {
            cta {
              back
              learnMore
              downloadPlan
              downloadFullPlan
            }
            footerCopyright
            footerNav {
              link
              name
            }
            footerNewsLetter {
              placeHolder
              thankYou
              title
            }
            lightbox {
              cta {
                carousel
                enlarge
              }
            }
            mainNav {
              link
              name
            }
            newsEvents {
              cta {
                events
                news
                register
                viewAll
              }
              filter {
                all
                events
                news
                showing
              }
              title
              indexTitle
            }
            siteMetaData {
              author
              description
              title
            }
            translations {
              cta
              languages {
                link
                name
              }
            }
          }
        }
      }
      allBnJson {
        edges {
          node {
            cta {
              back
              learnMore
              downloadPlan
              downloadFullPlan
            }
            footerCopyright
            footerNav {
              link
              name
            }
            footerNewsLetter {
              placeHolder
              thankYou
              title
            }
            lightbox {
              cta {
                carousel
                enlarge
              }
            }
            mainNav {
              link
              name
            }
            newsEvents {
              cta {
                events
                news
                register
                viewAll
              }
              filter {
                all
                events
                news
                showing
              }
              title
              indexTitle
            }
            siteMetaData {
              author
              description
              title
            }
            translations {
              cta
              languages {
                link
                name
              }
            }
          }
        }
      }
    }
  `)
  const lowerLang = lang.lang.toLowerCase()
  let capLang = lowerLang.charAt(0).toUpperCase() + lowerLang.charAt(1)
  capLang = capLang === 'Zh' ? 'ZhHant' : capLang

  useEffect(() => {
    const body = document.getElementsByTagName('body')
    body.style = ''
  })

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header
        siteTitle={data[`all${capLang}Json`].edges[0].node.siteMetaData.title}
        mainNav={data[`all${capLang}Json`].edges[0].node.mainNav}
        data={data[`all${capLang}Json`].edges[0].node}
        lang={lang.lang}
        homepage={homepage}
        />
        <main>{children}</main>
        {/* PreFooter already on hompage for sticky btn-tray */}
        {!homepage && (
          <PreFooter />
        )}

        <Footer
          lang={lang.lang}
          data={data[`all${capLang}Json`].edges[0].node}
          />
    </ThemeProvider>
  )
}

Layout.defaultProps = {
  homepage: false,
  lang: {
    lang: 'EN'
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  homepage: PropTypes.bool,
  lang: PropTypes.shape({
    lang: PropTypes.string,
  })
}

export default Layout
