import React from 'react'
import PropTypes from 'prop-types'
import styled, { Box,css, up} from '@xstyled/styled-components'

import Container from  './container'
import LogoAmtrak from '../../svgs/logos/logo-amtrak.svg'
// import LogoNYCEDC from '../../svgs/logos/logo-nycedc.svg'
import LogoNYCSeal from '../../svgs/logos/logo-nycseal.svg'


const StyledSection = styled.section`
  padding: ${p => p.homepage ? '0 0 40px' : '80px 0 40px'};

  ${up(
    'md',
    css`
      padding: ${p => p.homepage ? '0 0 80px' : '100rpx 0 80px'};
    `
  )}
`


const LogoTray = styled(Box)`
  /* max-width: 316rpx; */
  max-width: 250rpx;
  flex-direction: row;
  margin: 0 auto;

  svg {
    width: 40%;
    height: auto;
  }

  ${up(
    'md',
    css`
      /* max-width: 566rpx; */
      max-width: 340rpx;

      svg {
        &.logo-amtrak,
        &.logo-nycedc {
          max-width: 175rpx;
        }

        &.logo-nycseal {
          max-width: 120rpx;
        }
      }
    `
  )}
`

const PreFooter = ({ className, homepage }) => {

  return (
    <StyledSection homepage={homepage} className={className}>
      <Container>
        <LogoTray row justifyContent="space-between" alignItems="center">
          <LogoAmtrak />
          <LogoNYCSeal />
          {/* <LogoNYCEDC /> */}
        </LogoTray>
      </Container>
    </StyledSection>
  )
}

export default PreFooter

PreFooter.defaultProps = {
  className: 'section fp-auto-height',
  homepage: false,
}

PropTypes.propTypes = {
  className: PropTypes.string,
  homepage: PropTypes.bool
}
