import { css } from '@xstyled/styled-components'

const Color = css`

  :root {
    --ssy-black: #131313;
    --ssy-orange: #D9381C;
    --ssy-gray: #F2F2F2;
    --ssy-white: #FFFFFF;
  }

`
export default Color