import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { Box, css, down } from '@xstyled/styled-components'

import Container from './container'
// import IconChevronRightCircle from '../../svgs/icons/icon-chevron-right-circle.svg'
import LogoSSY from '../../svgs/logos/logo-ssy.svg'

const StyledFooter = styled.footer`
  background-color: var(--ssy-black);
  color: var(--ssy-white);
  padding: 75rpx 0 40rpx;

  ${down(
    'md',
    css`
      padding: 60rpx 0 40rpx;
    `
  )}
`;

const FooterNav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: 80rpx;

  ${down(
    'md',
    css`
      flex-direction: column;
      margin-bottom: 40rpx;
    `
  )}
`

const FooterNavItem = styled(Link)`
  display: inline-block;
  width: auto;
  margin: 0 50rpx 0 0;
  padding-bottom: 4rpx;
  color: var(--ssy-white);
  font-weight: 800;
  text-decoration: none;
  border-bottom: 2px solid var(--ssy-orange);

  &:hover {
    border-color: transparent;
    color: var(--ssy-orange);
    border-color: transparent;
  }

  ${down(
    'md',
    css`
    width: fit-content;
      margin: 0 0 20rpx 0;
    `
  )}
`

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14rpx;
  line-height: 16rpx;

  .logo-ssy {
    width: 166rpx;
    height: auto;
    margin-bottom: 12rpx;
  }
`

// const NewsLetter = styled.div`
//   display: flex;
//   flex-direction: column;
//   font-size: 16rpx;
//   line-height: 19rpx;
//   font-weight: 800;

//   ${down(
//     'md',
//     css`
//       padding-bottom: 60rpx;
//     `
//   )}
// `

// const NewsLetterTitle = styled.div`
//   margin-bottom: 20rpx;
// `

// const NewsLetterForm = styled.form`
//   display: flex;
//   flex-direction: row;
//   position: relative;

//   input {
//     display: flex;
//     flex-grow: 1;
//     padding: 10rpx 14rpx;
//     font-size: 16rpx;
//     line-height: 24rpx;
//     color: var(--ssy-white);
//     background-color: transparent;
//     border: 2px solid var(--ssy-orange);

//     &::placeholder {
//       color: var(--ssy-white);
//     }
//   }

//   button {
//     position: absolute;
//     width: 48rpx;
//     background-color: transparent;
//     border: 0;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     z-index: 2;

//     .icon-chevron-right-circle {
//       width: 12rpx;
//       height: 12rpx;

//       g > g {
//         fill: var(--ssy-white);
//       }
//     }

//     &:hover {
//       cursor: pointer;
//       .icon-chevron-right-circle {
//         g > g {
//           fill: var(--ssy-orange);
//         }
//       }
//     }
//   }
// `

const Footer = ({ className, data, lang }) => {

  return (
    <StyledFooter className={className}>
        <Container>
            <Box row mx={-27.5}>
              <Box col={{ xs: 1, sm: 1, md: 2 / 3 }} px={27.5}>
                <FooterNav>
                  {data.footerNav.map(link => (
                    <FooterNavItem
                      key={link.name}
                      to={`${lang === 'EN' ? '' : `/${lang}`}${link.link}`}>{link.name}</FooterNavItem>
                  ))}
                </FooterNav>
              </Box>
              <Box col={{ xs: 1, sm: 1, md: 1 / 3 }} px={27.5}>
                {/* <NewsLetter>
                  <NewsLetterTitle>{data.footerNewsLetter.title}</NewsLetterTitle>
                  <NewsLetterForm>
                    <input type="text" placeholder={data.footerNewsLetter.placeHolder} />
                    <button type="submit" aria-label="submit email">
                      <IconChevronRightCircle />
                    </button>
                  </NewsLetterForm>
                </NewsLetter> */}
              </Box>
            </Box>
            
            <FooterBottom className="footer-bottom">
              <LogoSSY />
              © {new Date().getFullYear()}, {data.footerCopyright}
            </FooterBottom>
        </Container>
    </StyledFooter>
  )
}

export default Footer;

Footer.defaultProps = {
  lang: 'EN'
}

Footer.propTypes ={
  lang: PropTypes.string
}