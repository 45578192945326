import React, { useState, useEffect } from 'react'
import styled, { css, up } from  '@xstyled/styled-components'
import anime from 'animejs'
import _ from 'underscore'
import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import BtnTranslate from './btn-translate'
import IconClose from '../svgs/icons/icon-close.svg'
import IconMenu from '../svgs/icons/icon-menu.svg'
import LogoSSY from '../svgs/logos/logo-ssy.svg'

const BtnNav = styled.button`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40rpx;
  height: 40rpx;
  background-color: var(--ssy-orange);
  border: 0;
  top: 10rpx;
  right: 0;
  z-index: 98;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: var(--ssy-black);
  }

  ${up(
    'md',
    css`
      display: none;
    `
  )}
`
const NavMobile = styled.div`
  display: flex;
  position: fixed;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--ssy-white);
  z-index: 97;
  transform: translateX(110vw);
  overflow-y: auto;

  ${up(
    'md',
    css`
      transform: translateX(110vw) !important;
    `
  )}
`

const InnerWrapper = styled.div`
  margin-top: 100rpx;
  -webkit-overflow-scrolling: touch;

  .logo-ssy {
    width: 240rpx;
    height: auto;
    margin-bottom: 60rpx;

    > g > g {
      fill: var(--ssy-black);
    }
  }

  .btn-translate {
    width: fit-content;
    margin: 100rpx auto 60rpx;

    .dropdown-menu {
      margin: 0 auto;
      margin-bottom: 60rpx;
    }
  }
`

const NavWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const NavItem = styled.a`
  display: block;
  margin-bottom: 20rpx;
  padding: 10rpx;
  font-size: 15rpx;
  font-weight: 800;
  text-align: center;
  text-decoration: none;
  color:  var(--ssy-orange);
  opacity: 0;
  transition: color 0.5s ease-in-out;
  transform: translateX(100px);

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    color: var(--ssy-black);
  }

  &.active {
    color: var(--ssy-black);
  }
`

const BtnNavMobile = ({ data, lang }) => {
  const [displayNavMobile, setDisplayNavMobile] = useState(false)

  const openNavMobile = () => {
    const openNavAnime = anime.timeline({
      easing: 'easeInOutQuad',
      duration: 400,
      autoplay: false
    })

    openNavAnime
      .add({
        targets: `#nav-home-mobile`,
        translateX: ['110vw', '0']
      })
      .add({
        targets: '#nav-home-mobile .nav-mobile-item',
        opacity: [0, 1],
        translateX: ['100px', '0px'],
        delay: anime.stagger(100)
      }, '-=450')

      openNavAnime.play()
  }

  const closeNavMobile = () => {
    const closeNavAnime = anime.timeline({
      easing: 'easeInOutQuad',
      duration: 400,
      autoplay: false
    })
  
    closeNavAnime
      .add({
        targets: `#nav-home-mobile`,
        translateX: ['0', '110vw']
      })
      .add({
        targets: '#nav-home-mobile .nav-mobile-item',
        opacity: [1, 0],
        translateX: ['0px', '100px'],
        delay: anime.stagger(100)
      }, '-=450')
  
      closeNavAnime.play()
  }

  const toggleNavMobile = (scrollY) => {
    setDisplayNavMobile(!displayNavMobile)
    if (!displayNavMobile) {
      document.body.style.top = `-${scrollY}px`
      document.body.style.position = 'fixed'
      document.body.style.width ='100vw'
      openNavMobile()
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.width =''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
      closeNavMobile()
    }
  }

  useEffect((displayNavMobile, toggleNavMobile) => {
    const handleWindowHeight = _.debounce(() => {
      if (window.innerWidth > 786) {
        if (displayNavMobile) {
          toggleNavMobile(window.scrollY)
        }
      }
    }, 300)
    window.addEventListener('resize', handleWindowHeight)

    return () => {
      window.removeEventListener('resize', handleWindowHeight)
    }
  }, [])

  return (
    <>
      <BtnNav aria-label="toggle navigation" onClick={() => toggleNavMobile(window.scrollY)}>
        {displayNavMobile ? (
          <IconClose />
        ) : (
          <IconMenu />
        )}     
      </BtnNav>
      <NavMobile id="nav-home-mobile">
        <InnerWrapper>
          <LogoSSY />
          <NavWrap>
            {data.mainNav.map(nav => (
              <NavItem
                key={nav.name}
                href={`/${lang === 'EN' ? '' : `${lang}/`}#${nav.link}`}
                className="nav-mobile-item"
                onClick={() => toggleNavMobile(window.scrollY)}
                >
                  {nav.name}
              </NavItem>
            ))}
          </NavWrap>
          {isMobile && (
            <BtnTranslate lang={lang} data={data} select />
          )}

          {isBrowser && (
            <BtnTranslate lang={lang} data={data} />
          )}
        </InnerWrapper>
      </NavMobile>
    </>
  )
}

export default BtnNavMobile