import { css } from '@xstyled/styled-components'

import Color from './color'
import Typography from './typography'

const GlobalStyles =css`

  html {
    overflow-x: hidden !important;
  }

  ${Color}
  ${Typography}

  hr {
    margin: 60rpx 0;
    border-color: var(--ssy-black);
    opacity: 0.1;
  }

  button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none;
  }

  *:focus {
    outline: 0;
  }

  body.fp-viewing-priority-details {
    .priority-title-sticky-desktop,
    .priority-title-sticky-mobile {
      visibility: visible;
      transition-delay: 0.5s;
    }
  }
`

export default GlobalStyles